export const LoadingStrings = [
  'chaining all the blocks',
  'petting doges',
  'feeding cypto kittens',
  'charging batteries',
  'reversing the polarity',
  'changing lightbulbs',
  'analyzing cropcircles',
  'contacting extra-terrestrials',
  'hiding treasures',
  'incinerating ugly sweaters',
  'scrambling eggs',
  'sharpening swords',
  'planting trees',
  'busting ghosts',
  'cleaning litter trays',
  'saving turtles',
  'baking muffins',
  'levelling up',
]

export default LoadingStrings
