import { Box, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingStrings } from './loading-strings'
import { ThreeDots } from '@agney/react-loading'

export interface LoadingProps {
  /**
   * Optional text override, else a random text string will be picked
   */
  text?: string
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      width: '50px',
    },
    loadingText: {
      fontFamily: 'Quicksand',
    },
  }
})

export const ICLoadingDots = ({ text, ...props }: LoadingProps) => {
  const loadingText = text
    ? text
    : LoadingStrings[Math.floor(Math.random() * LoadingStrings.length)]

  const classes = useStyles()
  return (
    <>
      <Box className={classes.container}>
        <ThreeDots />
      </Box>

      <Typography className={classes.loadingText}>{loadingText}</Typography>
    </>
  )
}

export default ICLoadingDots
