/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react'
import './App.css'
import { Navbar } from './components/navigation/navbar'
import { ThemeProvider, Theme } from '@mui/material/styles'
import { themeDark, themeLight } from './theme'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LandingPage } from './pages'
import CssBaseline from '@mui/material/CssBaseline'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const [darkMode, setDarkMode] = useState(true)

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
    console.log(darkMode)
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : themeLight}>
      <CssBaseline />
      <>
        <Navbar toggleDarkMode={() => toggleDarkMode()} darkMode={darkMode} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </BrowserRouter>
      </>
    </ThemeProvider>
  )
}

export default App
