import { Typography } from '@mui/material'
import { ICLoadingDots } from '../components'

export const LandingPage = () => {
  return (
    <>
      <Typography variant={'h1'}>Welcome to Infinity Qube</Typography>
      <ICLoadingDots />
    </>
  )
}

export default LandingPage
